<template>
  <div class="develop-container">
    <h2>设置设备维度VIP</h2>
    <div>
      <div><label>PAAS节点:</label></div>
      <template v-for="(node) in paasNodes">
        <span style="margin-right: 10px;">
          <input type="radio" :value="node" v-model="paasNode" class="my-btn"/>{{ node.nodeName }}
        </span>
      </template>
    </div>
    <div>accountId: {{ paasNode.accountId }}</div>
    <div>tenantId: {{ paasNode.tenantId }}</div>
    <div>
      <span>未使用兑换码:</span>
      <a-textarea type="text" v-model="unusedCodesStr" style="width: 600px"/>
    </div>
    <div>
      <span>已使用兑换码:</span>
      <a-textarea type="text" v-model="usedCodesStr" style="width: 600px" disabled/>
    </div>
    <div>
      <span>sn:</span>
      <input type="text" v-model="sn" style="width: 400px"/>
    </div>
    <div>
      <button @click="addSn(this.sn)" class="my-btn">添加sn</button>
      <button @click="addSn(null)" class="my-btn">刷新所有sn</button>
      <button @click="clearSns()" class="my-btn">清空</button>
    </div>
    <template v-for="(item) in deviceList" :key="item.serialNumber">
      <hr/>
      <div>
        <span>{{ item.serialNumber }}</span>
        <span style="margin-left: 20px">vipLevel:</span>
        <!--        <span>{{ vipLevelNames[parseInt(item.productId)] }}</span>-->
        <!--        <a-select v-model="item.category" @change="onProductIdChange(item)" :style="{width:'200px'}"-->
        <a-select v-model="item.category" :style="{width:'200px'}"
                  placeholder="Please select ...">
          <a-option v-for="(p) in products" :key="p.id" :value="p.id"
                    @click="onProductIdChange(item,p.id)">{{ p.name }}
          </a-option>
        </a-select>
        <span>productId:{{ item.productId }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import request from "@/utils/request";

const paasNodes = [
  {nodeName: "staging-cn", accountId: "paastest", tenantId: "paastest"},
  {nodeName: "staging-eu", accountId: "paastest", tenantId: "paastest"},
  {nodeName: "staging-us", accountId: "paastest", tenantId: "paastest"},
  {nodeName: "prod-cn", accountId: "sdkdemo", tenantId: "sdkdemo"},
  {nodeName: "prod-eu", accountId: "sdkdemo", tenantId: "sdkdemo"},
  {nodeName: "prod-us", accountId: "sdkdemo", tenantId: "sdkdemo"},
  {nodeName: "prod-cn", accountId: "changshi", tenantId: "longse"},
  {nodeName: "prod-eu", accountId: "changshi", tenantId: "longse"},
  {nodeName: "prod-us", accountId: "changshi", tenantId: "longse"}
];

export default {
  data() {
    return {
      paasNode: paasNodes[0],
      paasNodes: paasNodes,
      sn: null,
      sns: [],
      deviceList: [],
      products: [
        {id: "0", name: "0-noVip"},
        {id: "1", name: "1-basic"},
        {id: "2", name: "2-plus"},
        {id: "3", name: "3-pro"},
        {id: "4", name: "4-免费试用"},
        {id: "e", name: "5,6,7-兑换码"}
      ],
      unusedCodes: [],
      usedCodes: []
    };
  },
  methods: {
    clearSns() {
      this.sns = [];
      this.deviceList = [];
    },
    async addSn(sn) {
      sn = (sn || "").trim();
      if (sn !== "" && this.sns.findIndex(it => it === sn) == -1) {
        this.sns.push(sn);
      }
      const resp = await this.queryDeviceVip(this.accountId, this.tenantId, this.sns);
      /*
      for (const i in this.sns) {
        const item = resp.data[i];
        if (!item.serialNumber) {
          item.serialNumber = this.sns[i];
        }
      }
      */
      this.deviceList = resp.data.map(it => {
        if (it.productId.startsWith("e")) {
          return {...it, category: 'e'};
        } else {
          return {...it, category: it.productId};
        }
      });
    },
    async onProductIdChange(record, category) {
      console.log("onProductIdChange:", category, record);
      if (category === 'e') {
        const code = this.unusedCodes.shift();
        this.usedCodes.unshift(code);
        const resp = await this.useExchangeCode(this.accountId, this.tenantId, record.serialNumber, code);
        this.$message.info(resp.message);
      } else {
        const productId = category;
        const resp = await this.updateDeviceVip(this.accountId, this.tenantId, record.serialNumber, productId);
        this.$message.info(resp.message);
      }
    },
    async queryDeviceVip(accountId, tenantId, sns) {
      const requestConfig = {
        url: "/aiDemo/invokePaasApi",
        method: "post",
        params: {
          accountId: this.paasNode.accountId,
          tenantId: this.paasNode.tenantId,
          nodeName: this.paasNode.nodeName,
          path: `/open-api/device-vip/query`,
          method: "POST"
        },
        data: {
          tenantId: this.paasNode.tenantId,
          serialNumbers: sns
        }
      };
      return await request(requestConfig);
    },
    async updateDeviceVip(accountId, tenantId, sn, productId) {
      const requestConfig = {
        url: "/aiDemo/invokePaasApi",
        method: "post",
        params: {
          accountId: this.paasNode.accountId,
          tenantId: this.paasNode.tenantId,
          nodeName: this.paasNode.nodeName,
          path: `/open-api/device-vip/update`,
          method: "POST"
        },
        data: {
          tenantId: this.paasNode.tenantId,
          serialNumber: sn,
          productId: productId
        }
      };
      return await request(requestConfig);
    },
    async useExchangeCode(accountId, tenantId, sn, code) {
      const requestConfig = {
        url: "/aiDemo/invokePaasApi",
        method: "post",
        params: {
          accountId: this.paasNode.accountId,
          tenantId: this.paasNode.tenantId,
          nodeName: this.paasNode.nodeName,
          path: `/open-api/device-vip/exchange-code`,
          method: "POST"
        },
        data: {
          tenantId: this.paasNode.tenantId,
          serialNumber: sn,
          code: code
        }
      };
      return await request(requestConfig);
    },
    async exchangeCode(accountId, tenantId, sn, code) {
      const requestConfig = {
        url: "/aiDemo/invokePaasApi",
        method: "post",
        params: {
          accountId: this.paasNode.accountId,
          tenantId: this.paasNode.tenantId,
          nodeName: this.paasNode.nodeName,
          path: `/open-api/device-vip/exchange-code`,
          method: "POST"
        },
        data: {
          tenantId: this.paasNode.tenantId,
          serialNumber: sn,
          code: code
        }
      };
      return await request(requestConfig);
    }
  },
  computed: {
    unusedCodesStr: {
      set(val) {
        this.unusedCodes = val.split(',');
      },
      get() {
        this.unusedCodes.join(',');
      }
    },
    usedCodesStr: {
      set(val) {
        this.usedCodes = val.split(',');
      },
      get() {
        this.usedCodes.join(',');
      }
    }
  },
  watch: {
    paasNode() {
      this.clearSns();
    }
  }
};
</script>

<style lang="scss" scoped>

.my-btn {
  padding: 3px 5px;
  margin: 5px 10px;
  font-size: 16px;
}

</style>
